import { TitleH2, TitleMain, TitleP } from "@/StyledComponents/FrontendStyled";
import { Icon } from "@iconify/react";
import gsap from "gsap";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container } from "reactstrap";
import {
  AIMLList,
  PerfectMatchCard,
  PerfectMatchCardInner,
  PerfectMatchCol,
  PerfectMatchIcon,
  PerfectMatchMain,
  ScaleButton,
  StrugglingMain,
} from "../../StyledComponents/TeamStyled";
import FeelPerfactMatchSvg from "../svg/FeelPerfactMatchSvg";
import PerMatchArrowSvg from "../svg/Home/PerMatchArrowSvg";
import PerMatchCurveSvg from "../svg/Home/PerMatchCurveSvg";
import MatchCurveSvg from "../svg/MatchCurveSvg";
import { ScrollTrigger } from "gsap/all";
const listItems = [
  "Skills",
  "Approach",
  "Proactivity",
  "Responsibility",
  "Creativity",
];
const listItems2 = [
  "Apply for a 3-day trial where you task one of our designers with a small project",
  "Experience our creative process and collaboration style",
  "When the trial ends, we both decide if we’re ready for long-term commitment.",
];
gsap.registerPlugin(ScrollTrigger);
export default function FeelWeArePerMatch() {
  const leftColRef = useRef(null);
  const rightColRef = useRef(null);
  const arrowRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      leftColRef.current,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: leftColRef.current,
          start: "top 85%",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.fromTo(
      rightColRef.current,
      { opacity: 0, x: 100 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: rightColRef.current,
          start: "top 90%",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.to(arrowRef.current, {
      x: 15,
      duration: 0.7,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
  }, []);

  return (
    <Container className="p-b-section">
      <StrugglingMain className="!my-0 !py-0">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <TitleMain className=" py-0 md:!gap-0">
            <TitleH2 className="pt-[10px] !mb-[15px]">
              Feel we’re a perfect{" "}
              <span className="relative">
                match?
                <span className="absolute -bottom-[5px] right-[0px] left-[0px]">
                  <MatchCurveSvg />
                </span>
              </span>
            </TitleH2>
            <TitleP className="!mt-[0] text-[#77787A]">
              {" "}
              Let's check it out
            </TitleP>
          </TitleMain>
        </AnimationOnScroll>
        <PerfectMatchMain className="md:!p-0 !my-[0]">
          <PerfectMatchCol ref={leftColRef}>
            <PerfectMatchCard className="perfect-match-card card-left">
              {/* <StrugglingBoxHeading>Overworked Team</StrugglingBoxHeading> */}
              <PerfectMatchCardInner className="">
                Choosing a Developer for the web is often a game of luck.
                There’s no way to verify their…
                <AIMLList className="!ml-0 perfect">
                  {listItems.map((item, index) => (
                    <li key={index}>
                      <Image
                        src="https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg"
                        alt="Right Arrow"
                        width={19}
                        height={13}
                        loading="lazy"
                      />
                      {item}
                    </li>
                  ))}
                </AIMLList>
                ...until you start working
              </PerfectMatchCardInner>
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                animateOnce={true}
              >
                <PerMatchArrowSvg />
              </AnimationOnScroll>
            </PerfectMatchCard>
            <PerfectMatchIcon className="feelsvg">
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                animateOnce={true}
              >
                <FeelPerfactMatchSvg />
              </AnimationOnScroll>
            </PerfectMatchIcon>
          </PerfectMatchCol>
          <PerfectMatchCol ref={rightColRef} className="col-right">
            <div className="p-[0rem] md:p-[3.25rem] lg:p-[4.25rem]">
              <PerMatchCurveSvg />
            </div>
            <PerfectMatchCard className="card-right orange-app-btn">
              <PerfectMatchCardInner className="feelp">
                Choosing a good client is not an easy task either. So we offer
                you to put that collaboration to test before committing.
                <AIMLList className="feel-list !ml-0">
                  {listItems2.map((item, index) => (
                    <li key={index}>
                      <Image
                        src="/svgs/RightArrowWhite.svg"
                        alt="Right Arrow"
                        width={19}
                        height={13}
                        loading="lazy"
                        className=""
                      />
                      {item}
                    </li>
                  ))}
                </AIMLList>
                <Link
                  href="/hire-dedicated-developers"
                  target="_blank"
                  title="Hire Dedicated Developers"
                >
                  <ScaleButton className="white-app-btn uppercase !mt-[50px]">
                    Hire Dedicated Developers{" "}
                    <span ref={arrowRef}>
                      <Icon icon="tabler:arrow-right" />
                    </span>
                  </ScaleButton>
                </Link>
              </PerfectMatchCardInner>
            </PerfectMatchCard>
          </PerfectMatchCol>
        </PerfectMatchMain>
      </StrugglingMain>
    </Container>
  );
}
